// Destinations.jsx
import React from 'react';
import CardComponent from './CardComponent';

const Destinations = ({ urbanSpots, selectedOption, setSelectedOption, setUrbanSpotId }) => {
  return (
    <div className="container mt-5">
      {/* Frase de propuesta de valor con estilo más prominente */}


      <h4>Descubre destinos globales: catedrales, paisajes y playas. ¿Dónde será tu próxima aventura?</h4>
      <div className="row mt-4">
        {urbanSpots.map((urbanSpot) => (
          <div className="col-sm-6 col-md-4 col-lg-3 mb-3" key={urbanSpot.id}>
            <CardComponent
              urbanSpot={urbanSpot}
              handleSelect={() => setSelectedOption(urbanSpot)}
              isSelected={selectedOption && selectedOption.id === urbanSpot.id}
              setUrbanSpotId={setUrbanSpotId}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Destinations;

