import React, { Component } from "react";
import { Container, Row, Col } from "react-grid-system";
import styled from "styled-components";
import axios from 'axios';
// Estilos para las tarjetas y las imágenes
// Estilos para las tarjetas y las imágenes
const NftItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  background: #fff;
  height: 100%; // Se asegura de que todas las tarjetas tengan la misma altura
`;

const NftItemImage = styled.img`
  width: 100%;
  height: 200px; // Altura fija, ajusta según tus necesidades
  object-fit: contain; // La imagen se ajustará dentro del área asignada
  background-color: #f8f8f8; // Color de fondo en caso de que la imagen no ocupe todo el espacio
  display: block; // Asegura que la imagen no sea inline
  margin: 0 auto; // Centra la imagen horizontalmente si es más pequeña que el contenedor
`;

const NftItemImageWrapper = styled.div`
  width: 100%;
  padding-top: 56.25%; // Proporción 16:9
  position: relative;
`;

const NftItemHeader = styled.div`
  background-color: #344FD1;
  color: #fff;
  text-align: center;
  padding: 10px;
`;

const NftItemContent = styled.div`
  padding: 10px;
  flex: 1; // Hace que el contenido se expanda para ocupar el espacio disponible
  display: flex;
  flex-direction: column; // Organiza el contenido en una columna
`;


const NftItemInfo = styled.div`
  margin-bottom: auto; // Empuja el resto del contenido hacia abajo
`;

const Button = styled.button`
  margin-top: auto; // Se coloca al final de la tarjeta
`;


const Modal = styled.div`
  display: ${props => props.open ? 'block' : 'none'};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;

const ModalContent = styled.div`
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  max-width: 600px;
`;

// Función para obtener el ID del video de YouTube de una URL
const getYoutubeVideoId = (url) => {
  // Primero verifica si es una URL de YouTube Shorts
  const shortsRegex = /\/shorts\/([^?&/#]*)/;
  const shortsMatch = shortsRegex.exec(url);

  if (shortsMatch && shortsMatch[1]) {
    return shortsMatch[1];
  }

  // Si no es un Short, entonces verifica si es una URL de video normal de YouTube
  const videoRegex = /[?&]v=([^&#]*)/;
  const videoMatch = videoRegex.exec(url);

  if (videoMatch && videoMatch[1]) {
    return videoMatch[1];
  }

  // Si no se encuentra ningún ID de video, retorna null
  return null;
};


export default class Responsive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nfts: [],
      showModal: false,
      selectedHost: null
    };
  }

  componentDidMount() {
    const pathBack = 'https://admin.opencity.com.mx';
    axios
      .get(`${pathBack}/api/hosts`)
      .then((response) => {
        this.setState({
          nfts: response.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  loadMore = () => {
    let nftState = this.state.nfts;
    let start = nftState.length;
    let end = nftState.length + 4;
    this.setState({
      nfts: [...nftState, ...(this.dummyData.slice(start, end))]
    });
  }

  onImgLoad = (img) => {
    img.style.height = `${img.offsetWidth}px`; // Ajustar la altura de la imagen al ancho para mantener la proporción
  }

  openModal = (host) => {
    this.setState({
      showModal: true,
      selectedHost: host
    });
  }

  closeModal = () => {
    this.setState({
      showModal: false,
      selectedHost: null
    });
  }

  render() {
    const { nfts, showModal, selectedHost } = this.state;
    const pathBack = 'https://admin.opencity.com.mx';

    return (
      <Container>
        <Row >
          {nfts.map((host, index) => (
            <Col key={index} lg={6} md={6} sm={6} xs={12} className="my-3">
              <NftItem>
                <div className="nft__item_wrap">
                <NftItemImage src={`${pathBack}/applicant_images/${host.photoFace}`} alt={`${host.first_name} ${host.last_name}`} />
                </div>
                <NftItemHeader>
                  <h4 style={{ fontSize: '1rem', color: 'white' }}>{host.first_name} {host.last_name}</h4>
                </NftItemHeader>
                <NftItemContent>
                <NftItemInfo>
                  <div className="nft__item_price">
                    <span style={{ fontSize: '0.9rem' }}>{host.pr}</span>
                  </div>
              
                  {host.has_car ? (
                    <div className="info_item">
                      <i className="fa fa-car"></i>
                      <span style={{ fontSize: '0.8rem' }}>Vehículo disponible</span>
                    </div>
                  ) : null}
                  <div className="nft__item_info">
                    <h3 style={{ marginBottom: '0.2rem', fontSize: '1rem' }}>Idiomas:</h3>
                    {host.language_skills && host.language_skills.length > 0 ? (
                      <ul style={{ marginTop: '0.2rem', fontSize: '0.9rem' }}>
                        {host.language_skills.map((language, index) => (
                          <li key={index}>{language.toUpperCase()}</li>
                        ))}
                      </ul>
                    ) : (
                      <p>No se encontraron idiomas.</p>
                    )}
                  </div>
                  <div className="nft__item_info">
                    <h3 style={{ marginBottom: '0.2rem', fontSize: '1rem' }}>Tipo de tour:</h3>
                    {host.tour_type && host.tour_type.length > 0 ? (
                      <ul style={{ marginTop: '0.2rem', fontSize: '0.9rem' }}>
                        {host.tour_type.map((type, index) => (
                          <li key={index}>{type.toUpperCase()}</li>
                        ))}
                      </ul>
                    ) : (
                      <p>No se encontraron tipos de tour.</p>
                    )}
                  </div>
                  <div className="info_item">
                    <i className="fa fa-book"></i>
                    <span style={{ color: 'black', fontSize: '0.9rem' }}>{host.experience}</span>
                  </div>
                  <div className="info_item">
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                  </div>
                  </NftItemInfo>
                  <Button className="btn btn-primary btn-block" onClick={() => this.openModal(host)}>Ver Video</Button>
                </NftItemContent>
              </NftItem>
            </Col>
          ))}
        </Row>
        <div className="col-lg-12">
          <div className="spacer-single"></div>
          {/* <button className="btn btn-primary btn-block" onClick={() => this.loadMore()}>Ver más</button> */}
        </div>
        <Modal open={showModal} onClick={this.closeModal}>
          <ModalContent onClick={(e) => e.stopPropagation()} style={{ width: '800px', height: '600px' }}>
            {selectedHost && (
              <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <h3>Video del Host {selectedHost.first_name} {selectedHost.last_name}</h3>
                <div className="video-container" style={{ flex: 1 }}>
                  <iframe
                    src={`https://www.youtube.com/embed/${getYoutubeVideoId(selectedHost.short_video)}`}
                    frameBorder="0"
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                    title="Video del Host"
                    style={{ width: '100%', height: '100%' }}
                  />
                </div>
                <button className="btn btn-primary btn-block mt-2" onClick={this.closeModal}>Cerrar</button>
              </div>
            )}
          </ModalContent>
        </Modal>
      </Container>
    );
  }
}
