import React, { useEffect, useState } from 'react';
import { Link } from '@reach/router';

import SliderMainParticle from '../components/SliderMainParticle';
//import CarouselCollection from '../components/CarouselCollection';
import ColumnNew from '../components/ColumnNew';
import AuthorList from '../components/authorList';
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import YoutubeCarousel from './videosYoutube';
import ReactHtmlParser from 'react-html-parser';
import PopupAd from './popupad';
import styled from 'styled-components';

const NavLink = props => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      // the object returned here is passed to the
      // anchor element's props
      return {
        className: isCurrent ? 'active' : 'non-active',
      };
    }}
  />
);


const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.sticky.white {
    background: #1755C2;
    border-bottom: solid 1px #1755C2;
  }
  header#myHeader.navbar .search #quick_search{
    color: #fff;
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader.navbar.white .btn, .navbar.white a, .navbar.sticky.white a{
    color: #fff;
  }
  header#myHeader .dropdown-toggle::after{
    color: #fff;
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: block !important;
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: #1755C2;
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #fff;
    }
    .item-dropdown .dropdown a{
      color: #fff !important;
    }
  }

  @media only screen and (max-width: 767px) {
    /* Estilos para pantallas pequeñas */
    .jumbotron {
      padding: 0;
      margin-bottom: 0;
    }
    .carousel {
      margin-bottom: 20px;
    }
    .container-fluid {
      padding: 20px;
    }
    .card-body {
      padding: 15px;
    }
    .card-title {
      font-size: 18px;
    }
    .card-text {
      font-size: 14px;
      line-height: 1.5;
    }
  }

  .slide::before {
    content: '';
    display: block;
    position: absolute;
    width: 20%;
    height: 80%;
    background: none;
    border-left: 1px solid #ccc; /* opcional: agregar un borde para separar los elementos */
    bottom: 0;
    left: 0;
}
.jumbotron {
  margin-bottom: 0 !important;
}
.carousel-item img {
  max-height: 500px;
}

.form-check-label i {
  margin-right: 5px;
}
`;

// Añade estilos para mantener el título visible
const cardTitleStyle = {
  color: 'white',
  textShadow: '2px 2px 4px #000000',
  fontSize: '1.2rem',
  zIndex: 2, // Asegúrate de que el título está por encima de la imagen
};




const HomeOne = () => {
  const [additionalServices, setAdditionalServices] = useState([]);
  const pathBack = 'https://admin.opencity.com.mx';


  const [carouselImages, setCarouselImages] = useState([]);

  useEffect(() => {
    // Obtener servicios adicionales
    fetch(`${pathBack}/api/additional-services`)
      .then(response => response.json())
      .then(data => setAdditionalServices(data))
      .catch(error => console.error('Error al cargar servicios adicionales:', error));

    // Obtener imágenes del carrusel
    fetch(`${pathBack}/api/home-carousel-images`)
      .then(response => response.json())
      .then(data => setCarouselImages(data))
      .catch(error => console.error('Error al cargar imágenes del carrusel:', error));
  }, []);




  return (
    <div>
      <GlobalStyles />
      <section className="jumbotron no-bg">
        <div id="myCarousel" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-indicators">
            {carouselImages.map((image, index) => (
              <button
                key={image.id}
                type="button"
                data-bs-target="#myCarousel"
                data-bs-slide-to={index}
                className={index === 0 ? 'active' : ''}
                aria-current={index === 0 ? 'true' : 'false'}
                aria-label={`Slide ${index + 1}`}
              ></button>
            ))}
          </div>
          <div className="carousel-inner">
            {carouselImages.map((image, index) => (
              <div key={image.id} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                <img
                  src={`${pathBack}${image.path}`}
                  className="d-block w-100" alt={`Slide ${index + 1}`} />
              </div>
            ))}
          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#myCarousel" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#myCarousel" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>

        <div className="value-proposition my-3 py-3 shadow rounded text-center d-md-block"
          style={{ backgroundColor: '#f0e5d8', color: '#4a4e69', fontSize: '1.5rem', marginTop: '4rem' }}>

          Coordinadores de experiencias turísticas
        </div>


        <SliderMainParticle />


      </section>


      <section className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='text-center'>
              <h2>Experiencias turísticas</h2>
              <div className="small-border"></div>
            </div>
          </div>
        </div>

        <ColumnNew />


      </section>


      <section className='container-fluid bg-gray'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='text-center'>
                <h2>Servicios Complementarios</h2>
                <div className="small-border"></div>
              </div>
            </div>
          </div>
          <div className='row'>
            {additionalServices.slice(0, 9).map(service => (
              <div className='col-lg-6 mt-4' key={service.id}>
                <div className='card h-100 d-flex flex-column'> {/* Uso de flexbox para mantener la estructura de la tarjeta */}
                  <div className='card-img-top' style={{ height: '150px', overflow: 'hidden' }}>
                    <img src={`${pathBack}/additional_services/${service.image}`} alt={`Actividad ${service.id}`} style={{ width: '100%', height: '150px', objectFit: 'contain' }} />
                  </div>
                  <div className='card-body d-flex flex-column'>
                    <h5 className='card-title'>{service.title}</h5>
                    <p className='card-text' style={{ flex: 1 }}>{ReactHtmlParser(service.description)}</p>
                    <a href={`https://wa.me/524922190161?text=Estoy%20interesado%20en%20el%20servicio%20${encodeURIComponent(service.title)}`}
                      className='btn btn-primary mt-auto'
                      style={{ alignSelf: 'center' }}>
                      Contactar por WhatsApp
                    </a>
                  </div>
                </div>
              </div>
            ))}



          </div>

          <div className='row mt-4'>
            <div className='col-lg-12'>
              <div className='text-center'>

                <NavLink to="/works" className='btn active' >Ver más</NavLink>
              </div>

            </div>
          </div>
        </div>
      </section>


      {/**
    nUEVO REEL PARA FACEBOOK
    */}

      <section className='container-fluid bg-gray'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='text-center'>
                <h2>Redes sociales</h2>
                <div className="small-border"></div>
              </div>
            </div>
          </div>

          <div className='row mt-4'>
            <div className='col-lg-4'>
            </div>
            <div className='col-lg-4'>
              <div className='text-center'>
                {/* Facebook Page Plugin */}
                <div className="fb-page"
                  data-href="https://www.facebook.com/opencity.zac"
                  data-tabs="timeline"
                  data-width="350"
                  data-height="500"
                  data-small-header="false"
                  data-adapt-container-width="true"
                  data-hide-cover="false"
                  data-show-facepile="true">
                </div>
              </div>
            </div>
            <div className='col-lg-4'>
            </div>

          </div>


        </div>
      </section>



      <YoutubeCarousel />







      <section className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='text-center'>
              <h2>Guías top</h2>
              <div className="small-border"></div>
            </div>
          </div>
          <div className='col-lg-12'>
            <AuthorList />
          </div>
        </div>

      </section>


      <section className='container-fluid bg-gray' style={{ background: 'none' }}>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='text-center'>
                <h2>Acerca de Open City</h2>

              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-6'>
              <div className="card">
                <img src="./img/acercade/1.png" className="card-img-top" alt="Imagen 1" />
                <div className="card-body">
                  <h5 className="card-title">TECNOLOGÍA Y TURISMO DE LA MANO</h5>
                </div>
              </div>
            </div>
            <div className='col-lg-6'>
              <div className="card">
                <img src="./img/acercade/2.png" className="card-img-top" alt="Imagen 2" />
                <div className="card-body">
                  <h5 className="card-title">ACERCA DE OPEN CITY</h5>
                </div>
              </div>
            </div>
          </div>
          <p></p>
          <div className='row mt-2'>
            <div className='col-lg-12'>
              <div className='card'>
                <div className='card-body'>
                  <p className='card-text'>
                    Open City se desarrolla desde 2018, idea por parte del Emprendedor y Director Edgar Isaac Hernández Ulloa donde nace la necesidad de permitir a turistas que visitan la ciudad de Zacatecas conozcan y disfruten de la ciudad plenamente.
                  </p>
                  <p className='card-text'>
                    Queremos garantizar una amplia lista de lugares para visitar, que van de museos hasta realizar rappel, recorrer montes en vehículos todo terreno, visitar los bares y clubs con mejor ambiente, y más.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <PopupAd /> */}

      <Footer />

    </div>
  );
};
export default HomeOne;