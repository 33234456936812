
import React, { useState } from 'react';
import HostsAndDateTimeSection from './HostsAndDateTimeSection';
import GuideList from './GuideList';

const ThirdScreen = ({
  availableHosts,
  startDateTimeHost,
  endDateTimeHost,
  urbanSpotId,
  setStartDateTimeHost,
  setEndDateTimeHost,
  selectedDate,
  selectedTime,
  selectedHost, // Recibir el estado selectedHost como prop
  setSelectedHost,
  setHasVisitedScreen,
  setNumberOfPeople,
  numberOfPeople,


}) => {

  const pathBack = 'https://admin.opencity.com.mx';


  const handleHostSelection = (host) => {



    setSelectedHost(host);
    setHasVisitedScreen(true)
  };



  return (
    <div>
      <HostsAndDateTimeSection
        startDateTimeHost={startDateTimeHost}
        setStartDateTimeHost={setStartDateTimeHost}
        endDateTimeHost={endDateTimeHost}
        setEndDateTimeHost={setEndDateTimeHost}
        urbanSpotId={urbanSpotId}
        selectedDate={selectedDate}
        selectedTime={selectedTime}
        setNumberOfPeople={setNumberOfPeople}
        numberOfPeople={numberOfPeople}
      />
      <GuideList availableHosts={availableHosts}
        handleHostSelection={handleHostSelection}
        selectedHost={selectedHost} // Pasar el estado selectedHost
      />
    </div>
  );
};

export default ThirdScreen;
