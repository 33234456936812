import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';

// Styled components para la imagen
const ImageContainer = styled.div`
  height: 200px; // Ajusta esta altura según tus necesidades
  background-color: #f8f8f8; // Color de fondo en caso de que la imagen sea más pequeña que el contenedor
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

// Estilizar la imagen para que cubra el contenedor completamente
const NftItemImage = styled.img`
  width: 100%;
  height: 200px; // La altura que elijas para el contenedor de la imagen
  object-fit: contain; // Mantendrá la imagen completa dentro del contenedor
  background-color: #f8f8f8; // Elige un color de fondo que armonice con tu diseño
  margin: auto; // Centra la imagen en el contenedor si es necesario
`;

const AuthorList = () => {
    const [authors, setAuthors] = useState([]);
    const [loading, setLoading] = useState(true);
    const pathBack = 'https://admin.opencity.com.mx';

    useEffect(() => {
        axios.get(`${pathBack}/api/top-rated-hosts`)
            .then((response) => {
                setAuthors(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error al cargar los datos:', error);
                setLoading(false);
            });
    }, []);

    return (
        <div className="row">
            {loading ? (
                <p>Cargando autores...</p>
            ) : (
                authors.map((author, index) => (
                    <div className="col-md-3 mb-4" key={index}>
                        <div className="author-card">
                        <div  style={{ textAlign: 'center', height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#f8f8f8' }}>
                            <NftItemImage src={`${pathBack}/applicant_images/${author.photoFace}`} alt={`${author.first_name} ${author.last_name}`} />
                        </div>
                            <div className="author-info" style={{ textAlign: 'center' }}>
                                <h5 className="author-name">{author.first_name} {author.last_name}</h5>
                                <p className="author-location">{author.location}</p>
                            </div>
                            <div className="rating" style={{ textAlign: 'center' }}>
                                {Array.from({ length: author.rating }, (_, starIndex) => (
                                    <i key={starIndex} className="fa fa-star"></i>
                                ))}
                            </div>
                            {/* ...resto del contenido... */}
                        </div>
                    </div>
                ))
            )}
        </div>
    );
};

export default AuthorList;
