import React, { useState, useEffect } from 'react';
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import axios from 'axios';
import Swal from 'sweetalert2';


const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.sticky.white {
    background: #1755C2;
    border-bottom: solid 1px #1755C2;
  }
  header#myHeader.navbar .search #quick_search{
    color: #fff;
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader.navbar.white .btn, .navbar.white a, .navbar.sticky.white a{
    color: #fff;
  }
  header#myHeader .dropdown-toggle::after{
    color: rgba(255, 255, 255, .5);
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: block !important;
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: #1755C2;
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #fff;
    }
    .item-dropdown .dropdown a{
      color: #fff !important;
    }
  }

  .justified-text {
    text-align: justify;
  }
  
`;

const Register = () => {


  const [first_name, setName] = useState('');
  const [last_name, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [photoFace, setPhotoFace] = useState(null);
  const [frontINE, setFrontINE] = useState(null);
  const [backINE, setBackINE] = useState(null);
  const [urbanSpots, setUrbanSpots] = useState([]);

  const [previewUrlPhotoFace, setPreviewUrlPhotoFace] = useState('./img/dummys/3.png');
  const [previewUrlFrontINE, setPreviewUrlFrontINE] = useState('./img/dummys/1.png');
  const [previewUrlBackINE, setPreviewUrlBackINE] = useState('./img/dummys/2.png');

  const inputPhotoFaceRef = React.useRef();
  const inputFrontINERef = React.useRef();
  const inputBackINERef = React.useRef();
  
  const [accessMessage, setAccessMessage] = useState('');
  const [urbanSpotId, setUrbanSpotId] = useState('');

  
  const handleImageChangePhotoFace = e => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    setPhotoFace(file);
    setPreviewUrlPhotoFace(URL.createObjectURL(file));
  };

  const handleImageChangeFrontINE = e => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    setFrontINE(file);
    setPreviewUrlFrontINE(URL.createObjectURL(file));
  };

  const handleImageChangeBackINE = e => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    setBackINE(file);
    setPreviewUrlBackINE(URL.createObjectURL(file));
  };

  const pathBack = 'https://admin.opencity.com.mx';

  useEffect(() => {
    fetch(`${pathBack}/api/urban-spots`)
      .then(response => response.json())
      .then(data => {
        // Modificar los valores de imagen
        const modifiedUrbanSpots = data.map(urbanSpot => ({
          id: urbanSpot.id,
          name: urbanSpot.name,
          photo: `${pathBack}/urban_spots/${urbanSpot.photo}` // Modifica la ruta de las imágenes según tus necesidades
        }));
        setUrbanSpots(modifiedUrbanSpots);
      })

      .catch(error => console.error(error));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // SweetAlert para confirmar si se desean guardar los datos
    Swal.fire({
      title: 'Confirmación',
      text: "¿Confirmas el envío de estos datos?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, enviar!',
      cancelButtonText: 'No, cancelar!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          let formData = new FormData();

          formData.append('first_name', first_name);
          formData.append('last_name', last_name);
          formData.append('email', email);
          formData.append('phone', phone);
          formData.append('photoFace', photoFace);
          formData.append('frontINE', frontINE);
          formData.append('backINE', backINE);
          formData.append('urbanSpotId', urbanSpotId);


          const response = await axios.post('https://admin.opencity.com.mx/api/register', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });

          // SweetAlert para indicar que los datos se han guardado correctamente
          Swal.fire(
            '¡Enviado!',
            'Tus datos han sido enviados.',
            'success'
          )


          setSuccessMessage(response.data.message);
          setErrorMessage('');
          setName('');
          setLastName('');
          setEmail('');
          setPhone('');
          setPhotoFace('');
          setFrontINE('');
          setBackINE('');
          inputPhotoFaceRef.current.value = "";
          inputFrontINERef.current.value = "";
          inputBackINERef.current.value = "";
          // Restablecer las imágenes a sus valores predeterminados
          setPreviewUrlPhotoFace('./img/dummys/3.png');
          setPreviewUrlFrontINE('./img/dummys/1.png');
          setPreviewUrlBackINE('./img/dummys/2.png');

        } catch (error) {
          setErrorMessage(error.response.data.message);
          setSuccessMessage('');
          Swal.fire(
            'Error',
            'Hubo un problema al enviar tus datos. Por favor, inténtalo de nuevo.',
            'error'
          );
          

          // Puedes agregar aquí un SweetAlert para manejar los errores si lo prefieres
        }
      }
    });
  };


  return (
    <div>
      <GlobalStyles />

      <section className='jumbotron breadcumb no-bg' style={{ backgroundImage: `url(${'./img/background/subheader.jpg'})` }}>
        <div className='mainbreadcumb'>
          <div className='container'>
            <div className='row'>
              <div className="col-md-12 text-center">
                <h1>Registrar</h1>
                <p>"Conviértete en un guía turístico y deja que tus pasos cuenten historias."</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='container'>
        <div className="row">

          <div className="col-md-8 offset-md-2">
            <h3>¿No tienes una cuenta? Regístrate ahora.</h3>
            <p className="justified-text">
              "¡Descubre un mundo de experiencias únicas en cada destino con Opencity! Únete a nuestro equipo de guías turísticos y sé parte de una comunidad apasionada que comparte conocimientos y secretos locales en fascinantes ciudades alrededor del mundo. Juntos, abriremos las puertas de cada destino y mostraremos su auténtica esencia a los viajeros. Únete a Opencity y ayúdanos a crear recuerdos inolvidables en cada rincón del planeta."
            </p>

            <div className="spacer-10"></div>

            <form name="contactForm" id="contact_form" className="form-border" onSubmit={handleSubmit}>

              <div className="row">

                <div className="col-md-6">
                  <div className="field-set">
                    <label>Fotografía Rostro:</label>
                    {previewUrlPhotoFace && <img src={previewUrlPhotoFace} alt="Preview" style={{ width: '100%', height: 'auto' }} />}
                    <input type='file' name='photoFace' id='photoFace' className="form-control" onChange={handleImageChangePhotoFace} ref={inputPhotoFaceRef} />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="field-set">
                    <label>Fotografía INE Frente:</label>
                    {previewUrlFrontINE && <img src={previewUrlFrontINE} alt="Preview" style={{ width: '100%', height: 'auto' }} />}
                    <input type='file' name='frontINE' id='frontINE' className="form-control" onChange={handleImageChangeFrontINE} ref={inputFrontINERef} />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="field-set">
                    <label>Fotografía INE Reverso:</label>
                    {previewUrlBackINE && <img src={previewUrlBackINE} alt="Preview" style={{ width: '100%', height: 'auto' }} />}
                    <input type='file' name='backINE' id='backINE' className="form-control" onChange={handleImageChangeBackINE} ref={inputBackINERef} />
                  </div>
                </div>

                <div className="col-md-6">
                </div>

                <div className="col-md-6">
                  <div className="field-set">
                    <label>Zona Urbana:</label>
                    <select name="urbanSpot" className="form-control" value={urbanSpotId} onChange={(e) => setUrbanSpotId(e.target.value)}>
                      <option value="">Selecciona una opción</option>
                      {urbanSpots.map(urbanSpot => (
                        <option key={urbanSpot.id} value={urbanSpot.id}>{urbanSpot.name}</option>
                      ))}
                    </select>
                  </div>
                </div>


                <div className="col-md-6">
                  <div className="field-set">
                    <label>Nombre:</label>
                    <input type='text' name='name' id='name' className="form-control" value={first_name} onChange={(e) => setName(e.target.value)} />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="field-set">
                    <label>Apellidos:</label>
                    <input type='text' name='lastName' id='lastName' className="form-control" value={last_name} onChange={(e) => setLastName(e.target.value)} />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="field-set">
                    <label>Correo electrónico:</label>
                    <input type='text' name='email' id='email' className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="field-set">
                    <label>Teléfono:</label>
                    <input type='text' name='phone' id='phone' className="form-control" value={phone} onChange={(e) => setPhone(e.target.value)} />
                  </div>
                </div>



                <div className="col-md-12">
                  <div id='submit' className="pull-left">
                    <input type='submit' id='send_message' value='Regístrate ahora' className="btn btn-main color-2" />
                  </div>

                  <div className="clearfix"></div>
                </div>

              </div>
            </form>
          </div>

        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Register;