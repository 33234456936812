import React, { useState, useEffect } from 'react';
import YouTube from 'react-youtube';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const YoutubeCarousel = () => {
    const [videoIds, setVideoIds] = useState([]);

    useEffect(() => {
      fetch('https://admin.opencity.com.mx/api/youtube-links/last-five')
        .then(response => response.json())
        .then(data => {
          const ids = data.map(link => link.video_id);
          setVideoIds(ids);
        })
        .catch(error => console.error(error));
    }, []);
  
    const opts = {
      height: '390',
      width: '100%',
      playerVars: {
        autoplay: 0,
      },
    };
  
    return (
      <div className="container">
        <div className="my-5 text-center">
          <h2>Nuestros Últimos Videos</h2>
          <p>Disfruta de los últimos videos que hemos subido en nuestra plataforma</p>
        </div>
        <div className="row">
          {videoIds.map((videoId, index) => (
            <div key={index} className="col-12 col-md-6 col-lg-4 mb-4">
              <YouTube videoId={videoId} opts={opts} />
            </div>
          ))}
        </div>
      </div>
    );
  };
export default YoutubeCarousel;
