import React, { useEffect, useState, useRef } from 'react';
import DatePicker from 'react-datepicker';
import { es } from 'date-fns/locale';
import 'react-datepicker/dist/react-datepicker.css';
import { addHours, subHours, differenceInHours } from 'date-fns';
import Swal from 'sweetalert2';

const HostsAndDateTimeSection = ({
  startDateTimeHost,
  setStartDateTimeHost,
  endDateTimeHost,
  setEndDateTimeHost,
  setNumberOfPeople,
  numberOfPeople
}) => {
  const [startTime, setStartTime] = useState("12:00");
  const [endTime, setEndTime] = useState("15:00");

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (startTime && startDateTimeHost) {
      const [hour, minute] = startTime.split(':');
      const updatedStartDate = new Date(startDateTimeHost);
      updatedStartDate.setHours(parseInt(hour), parseInt(minute));
      setStartDateTimeHost(updatedStartDate);

      const updatedEndDate = new Date(updatedStartDate);
      updatedEndDate.setHours(updatedStartDate.getHours() + 3);
      const newEndHour = updatedEndDate.getHours();
      const newEndMinute = updatedEndDate.getMinutes();
      const newEndTime = `${String(newEndHour).padStart(2, '0')}:${String(newEndMinute).padStart(2, '0')}`;
      
      setEndTime(newEndTime);
    }
  }, [startTime]);

  useEffect(() => {
    if (startTime && endTime && endDateTimeHost) {
      const [hour, minute] = endTime.split(':');
      const updatedEndDate = new Date(endDateTimeHost);
      updatedEndDate.setHours(parseInt(hour), parseInt(minute));
      setEndDateTimeHost(updatedEndDate);
    }
  }, [endTime]);

  useEffect(() => {
    if (startDateTimeHost) {
      const updatedEndDate = new Date(startDateTimeHost);
      updatedEndDate.setHours(startDateTimeHost.getHours() + 3);
      setEndDateTimeHost(updatedEndDate);
    }
  }, [startDateTimeHost]);

  return (
    <div>
      <h4 className='background-for-text'>Seleccione la fecha y hora para descubrir los mejores guías disponibles:</h4>
      <div className="row col-md-12">
        <div className="row">
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-5 col-sm-6 col-12">
                <label htmlFor="numberOfPeople">Número de personas:</label>
                <input
                  type="number"
                  id="numberOfPeople"
                  className="form-control"
                  value={numberOfPeople}
                  onChange={(e) => setNumberOfPeople(e.target.value)}
                  min="1"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-5 col-sm-6 col-12">
                <label className='background-for-text' htmlFor="startDate">Fecha de inicio:</label>
                <DatePicker
                  selected={startDateTimeHost}
                  onChange={(date) => setStartDateTimeHost(date)}
                  dateFormat="MMMM d, yyyy"
                  locale={es}
                  className="form-control background-for-text"
                  id="startDate"
                  minDate={new Date()}
                />
              </div>
              <div className="col-md-5 col-sm-6 col-12">
                <label htmlFor="startTime">Hora de inicio:</label>
                <input
                  type="time"
                  id="startTime"
                  className="form-control"
                  value={startTime}
                  onChange={(e) => setStartTime(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="row">
              <div className="col-md-5 col-sm-6 col-12">
                <label htmlFor="endDate">Fecha de fin:</label>
                <DatePicker
                  selected={endDateTimeHost}
                  onChange={(date) => setEndDateTimeHost(date)}
                  dateFormat="MMMM d, yyyy"
                  locale={es}
                  className="form-control"
                  id="endDate"
                  minDate={startDateTimeHost || new Date()}
                />
              </div>
              <div className="col-md-5 col-sm-6 col-12">
                <label htmlFor="endTime">Hora de fin:</label>
                <input
                  type="time"
                  id="endTime"
                  className="form-control"
                  value={endTime}
                  onChange={(e) => setEndTime(e.target.value)}
                  disabled={!startDateTimeHost}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HostsAndDateTimeSection;
