import React, { useState, useEffect } from 'react';

import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import ReactHtmlParser from 'react-html-parser';

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.sticky.white {
    background: #1755C2;
    border-bottom: solid 1px #1755C2;
  }
  header#myHeader.navbar .search #quick_search{
    color: #fff;
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader.navbar.white .btn, .navbar.white a, .navbar.sticky.white a{
    color: #fff;
  }
  header#myHeader .dropdown-toggle::after{
    color: rgba(255, 255, 255, .5);
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: block !important;
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: #1755C2;
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #fff;
    }
    .item-dropdown .dropdown a{
      color: #fff !important;
    }
  }
`;


export default function Works() {

  const [additionalServices, setAdditionalServices] = useState([]);
  const pathBack = 'https://admin.opencity.com.mx';

  useEffect(() => {
    fetch(`${pathBack}/api/additional-services`)
      .then(response => response.json())
      .then(data => setAdditionalServices(data))
      .catch(error => console.error(error));
  }, []);

  return (
    <div>
      <GlobalStyles />
      <section className='jumbotron breadcumb no-bg' style={{ backgroundImage: `url(${'./img/background/subheader.jpg'})` }}>
        <div className='mainbreadcumb'>
          <div className='container'>
            <div className='row'>
              <div className="col-md-12 text-center">
                <h1>Servicios complementarios</h1>
                <p>Descubre una gama de servicios complementarios que transformarán tu experiencia en una aventura inolvidable.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='container-fluid bg-gray' style={{ background: 'none' }}>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='text-center'>
                <h2>Servicios Complementarios</h2>
                <div className="small-border"></div>
              </div>
            </div>
          </div>
          <div className='row'>


            {additionalServices.slice(0, 9).map(service => (
              <div className='col-lg-4 mt-4' key={service.id}>
                <div className='card h-100 d-flex flex-column'> {/* Uso de flexbox para mantener la estructura de la tarjeta */}
                  <div className='card-img-top' style={{ height: '150px', overflow: 'hidden' }}>
                    <img src={`${pathBack}/additional_services/${service.image}`} alt={`Actividad ${service.id}`} style={{ width: '100%', height: '150px', objectFit: 'contain' }} />
                  </div>
                  <div className='card-body d-flex flex-column'>
                    <h5 className='card-title'>{service.title}</h5>
                    <p className='card-text' style={{ flex: 1 }}>{ReactHtmlParser(service.description)}</p>
                    <a href={`https://wa.me/524922190161?text=Estoy%20interesado%20en%20el%20servicio%20${encodeURIComponent(service.title)}`}
                      className='btn btn-primary mt-auto'
                      style={{ alignSelf: 'center' }}>
                      Contactar por WhatsApp
                    </a>
                  </div>
                </div>
              </div>
            ))}


          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}