import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';

const RoomModal = ({
    showRoomModal,
    setShowRoomModal,
    availableRooms,
    // handleRoomCountChange,
    setAvailableRooms,
    peopleCount,
    handleRoomSelection,
    selectedGuestCounts,
    setSelectedGuestCounts,
    startDate,
    endDate,
    setSummary,
    setIsRoomSelected
}) => {
    const pathBack = 'https://admin.opencity.com.mx';



    const sendSelectedGuestCounts = async () => {
        try {
            const response = await fetch(`${pathBack}/api/calculate-total`, { // Nota el uso de pathBack aquí
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    selectedGuestCounts,
                    startDate,
                    endDate
                }),
            });

            if (response.ok) {
                const data = await response.json();

                console.log("data rooms", data)
                setSummary(data)
                setIsRoomSelected(true)

                console.log("Data received:", data);
            } else {
                console.error(`Failed to send data: ${response.status} ${response.statusText}`);
            }
        } catch (error) {
            console.error(`Failed to send data: ${error}`);
        }
    };


    const handleRoomTotals = async () => {


        await sendSelectedGuestCounts();
        // Aquí podrías agregar más código para cerrar el modal o actualizar el estado del componente, si es necesario
        setShowRoomModal(false);
    };


    useEffect(() => {
        const totalSelectedGuests = getTotalSelectedGuests();
        if (totalSelectedGuests > peopleCount) {
            Swal.fire({
                icon: 'error',
                title: 'Error en la selección',
                text: 'No puedes seleccionar más habitaciones que la cantidad de personas.',
            });
        }
    }, [selectedGuestCounts, peopleCount]);

  ;
    const getTotalSelectedGuests = () => {
        return Object.values(selectedGuestCounts).reduce((total, obj) => {
            return total + (Number(obj.count) || 0);  // Asegúrate de que es un número
        }, 0);
    };



    const handleGuestCountChange = (roomId, newGuestCount) => {
        const totalGuestsAfterChange = getTotalSelectedGuests() - (selectedGuestCounts[roomId]?.count || 0) + newGuestCount;


        if (totalGuestsAfterChange <= peopleCount) {
            setSelectedGuestCounts({
                ...selectedGuestCounts,
                [roomId]: {
                    count: newGuestCount,
                    roomId: roomId // Agregando el ID de la habitación aquí también
                }
            });
            const updatedRooms = availableRooms.map(room => {
                if (room.room.id === roomId) {
                    return { ...room, selectedGuestCount: newGuestCount };
                }
                return room;
            });
            setAvailableRooms(updatedRooms);
        } else {
         
        }
    };




    return (
        <Modal show={showRoomModal} onHide={() => setShowRoomModal(false)} centered >
            <Modal.Header >
                <Modal.Title>Habitaciones disponibles</Modal.Title>
                <Button
                    onClick={() => setShowRoomModal(false)}
                    style={{
                        background: 'white',
                        border: 'none',
                        color: 'black',  // Letra en negro
                        padding: '5px 10px',  // Ajusta el padding según tus necesidades
                        boxShadow: 'none'  // Quita cualquier sombra
                    }}
                >
                    X
                </Button>
            </Modal.Header>
            <Modal.Body>
                <div className="modal-content-scrollable">

                    <div className="guests-remaining mb-10" style={{ padding: '16px' }}>
                        <strong>Huéspedes totales: {peopleCount}</strong>
                        <span> / </span>
                        <strong>Asignados: {getTotalSelectedGuests()}</strong>
                        <span> / </span>
                        <strong>Restantes: {peopleCount - getTotalSelectedGuests()}</strong>
                    </div>

                    {availableRooms.map(roomItem => (
                        <div key={roomItem.room.id} className="d-item col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-4 mt-10" style={{ boxShadow: '0 4px 8px rgba(0,0,0,0.1)', borderRadius: '8px' }}>

                            <div className="nft__item m-0 mt-10" style={{ padding: '16px' }}>

                                <div className="mb-2">
                                    <strong style={{ fontSize: '1.2rem' }}>{roomItem.room.name}</strong>
                                </div>

                                <div className="nft__item_content mb-6">

                                    <div className="nft__item_img-and-selectors d-flex">

                                        <div className="nft__item_wrap mr-3" style={{ maxWidth: '150px', flexShrink: 0 }}>
                                            <img
                                                src={`${pathBack}/habitaciones/${roomItem.room.photo || "default_image_link_here"}`}
                                                className="lazy nft__item_preview"
                                                alt={roomItem.room.name}
                                                style={{ borderRadius: '8px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}
                                            />
                                        </div>

                                        <div className="room-selectors d-flex flex-column justify-content-center">
                                            {/* <div className="room-selector-group mb-2">
                                                <span className="room-label">Habitaciones:</span>
                                                <span className="room-count">{roomItem.selectedCount || 0}</span>
                                                <div className="room-button-group ml-2">
                                                    <button
                                                        className="room-button"
                                                        style={{ background: '#E5E7EB', border: 'none', borderRadius: '4px', marginRight: '8px' }}
                                                        onClick={() => handleRoomCountChange(
                                                            roomItem.room.id,
                                                            Math.max(0, (roomItem.selectedCount || 0) - 1),
                                                            availableRooms,
                                                            setAvailableRooms,
                                                            peopleCount
                                                        )}>
                                                        -
                                                    </button>
                                                    <button
                                                        className="room-button"
                                                        style={{ background: '#E5E7EB', border: 'none', borderRadius: '4px' }}
                                                        onClick={() => handleRoomCountChange(
                                                            roomItem.room.id,
                                                            Math.min(roomItem.room.room_count, (roomItem.selectedCount || 0) + 1),
                                                            availableRooms,
                                                            setAvailableRooms,
                                                            peopleCount
                                                        )}
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            </div> */}

                                            <div className="room-selector-group mb-2">
                                                <div className="room-label-group">
                                                    <span className="room-label">Huéspedes:</span>
                                                    <span className="room-count">{roomItem.selectedGuestCount || 0}</span>
                                                </div>
                                                <div className="room-button-group ml-2">
                                                    <button
                                                        className="room-button"
                                                        onClick={() => handleGuestCountChange(
                                                            roomItem.room.id,
                                                            Math.max(0, (roomItem.selectedGuestCount || 0) - 1)
                                                        )}
                                                    >
                                                        -
                                                    </button>
                                                    <button
                                                        className="room-button"
                                                        onClick={() => handleGuestCountChange(
                                                            roomItem.room.id,
                                                            Math.min(peopleCount, (roomItem.selectedGuestCount || 0) + 1)
                                                        )}
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            </div>

                                        </div>



                                    </div>

                                    <div className="nft__item_info mx-0 mb-6" style={{ textAlign: 'left', marginBottom: "7px", width: "95%" }}>
                                        <h4 className="mb-2">{roomItem.room.name}</h4>
                                        <div className="room-description mb-2">
                                            <strong>Descripción:</strong> {roomItem.room.description}
                                        </div>
                                        <div className="room-capacity mb-2">
                                            <strong>Capacidad:</strong> {roomItem.room.capacity} personas
                                        </div>
                                        <div className="room-quantity mb-2">
                                            <strong>Cantidad:</strong> {roomItem.room.room_count} disponibles
                                        </div>
                                        <div className="room-price mb-2">
                                            <strong>Precio:</strong>
                                            <span className="price-strikethrough ml-2" style={{ textDecoration: 'line-through', color: 'red' }}>
                                                {roomItem.price.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                                            </span>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    ))}
                </div>
            </Modal.Body>
            <Modal.Footer>

                <button onClick={handleRoomTotals} className="btn btn-main btn-fullwidth color-1">Confirmar Habitación</button>
                <Button className="btn btn-main btn-fullwidth color-2" onClick={() => setShowRoomModal(false)}>
                    Cancelar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default RoomModal;
