import React, { useState, useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.sticky.white {
    background: #1755C2;
    border-bottom: solid 1px #1755C2;
  }
  header#myHeader.navbar .search #quick_search{
    color: #fff;
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader.navbar.white .btn, .navbar.white a, .navbar.sticky.white a{
    color: #fff;
  }
  header#myHeader .dropdown-toggle::after{
    color: rgba(255, 255, 255, .5);
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: block !important;
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: #1755C2;
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #fff;
    }
    .item-dropdown .dropdown a{
      color: #fff !important;
    }
  }
`;


export default function Products() {

  const [products, setProducts] = useState([]);
  const pathBack = 'https://admin.opencity.com.mx';

  useEffect(() => {
    fetch(`${pathBack}/api/products`)
      .then(response => response.json())
      .then(data => setProducts(data))
      .catch(error => console.error(error));
  }, []);

  return (
    <div>
      <GlobalStyles />
      <section className='jumbotron breadcumb no-bg' style={{ backgroundImage: `url(${'./img/background/subheader.jpg'})` }}>
        <div className='mainbreadcumb'>
          <div className='container'>
            <div className='row'>
              <div className="col-md-12 text-center">
                <h1>Productos</h1>
                <p>Explora nuestra selección de productos y descubre las opciones perfectas para mejorar tu experiencia y hacer de cada momento una aventura inolvidable.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='container-fluid bg-gray' style={{ background: 'none' }}>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='text-center'>
                <h2>Productos</h2>
                <div className="small-border"></div>
              </div>
            </div>
          </div>
          <div className='row'>

            {products.slice(0, 9).map(product => (
              <div className='col-lg-4 mt-4' key={product.id}>
                <div className='card'>
                  <img src={`${pathBack}/product_images/${product.image}`} className='card-img-top' alt={`Actividad ${product.id}`} />
                  <div className='card-body'>
                    <h5 className="card-title">{product.name}<br></br></h5>
                    <h6 className="card-subtitle mb-2 text-muted mt-2">Precio: ${Number.parseFloat(product.price).toFixed(2)}</h6>
                    <h6 className="card-subtitle mb-2 text-muted">Stock: {product.stock}</h6>
                    <p className="card-text">      {ReactHtmlParser(product.description)}</p>
                    <a href={`https://wa.me/4922190161?text=Hola%2C%20me%20interesa%20saber%20más%20sobre%20el%20producto%20${product.name}`} className="btn btn-primary">Obtener información por WhatsApp</a>
                 
                    
                  </div>
                </div>
              </div>
            ))}

            
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}