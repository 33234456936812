// SelectionCard.jsx
import React from 'react';

const SelectionCard = ({ imageSrc, title, isSelected, onSelect }) => {
  // Placeholder mientras la imagen carga
  const cardStyle = {
    backgroundImage: `url(${imageSrc})`,
    backgroundSize: 'cover', // Asegúrate de que la imagen cubra toda la tarjeta
    backgroundPosition: 'center',
  };

  // Añade estilos para mantener el título visible
  const cardTitleStyle = {
    color: 'white',
    textShadow: '2px 2px 4px #000000',
    fontSize: '1.2rem',
    zIndex: 2, // Asegúrate de que el título está por encima de la imagen
  };

  return (
    <div className="col-12 col-sm-6 col-md-4 mb-3">
      <div
        className={`card selection-card ${isSelected ? 'border-primary' : ''}`}
        onClick={onSelect}
        style={cardStyle} // Usa estilos de imagen de fondo
      >
        {/* La imagen no es necesaria si usas un fondo */}
        <div className="card-body">
          <h5 className="card-title text-center" style={cardTitleStyle}>{title}</h5>
        </div>
      </div>
    </div>
  );
}

export default SelectionCard;
