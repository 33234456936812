import React, { useState } from 'react';
import axios from 'axios';
import { Link } from '@reach/router';

import Swal from 'sweetalert2';
const Footer = () => {
    const [email, setEmail] = useState('');

    const pathBack = 'https://admin.opencity.com.mx';
    const [showModal, setShowModal] = useState(false);

    const toggleModal = () => {
        setShowModal(!showModal);
    };


    const subscribeToNewsletter = (e) => {
        e.preventDefault();

        axios.post(`${pathBack}/api/newsletter`, { email })
            .then((response) => {
                // Aquí puedes manejar la respuesta del servidor
                console.log(response.data);

                Swal.fire(
                    '¡Enviado!',
                    'Registrado exitosamente.',
                    'success'
                )

            })
            .catch((error) => {
                // Aquí puedes manejar el error
                Swal.fire(
                    '¡Error!',
                    'Error al registrarte.',
                    'error'
                )
            });
    };
    return (
        <footer className="footer-light">
            <div className="container">
                <div className="row">
                    <div className="col-md-5 offset-md-3 col-sm-6 col-xs-12 text-center">
                        <div className="widget">
                            <h5>Newsletter</h5>
                            <p>Suscríbase a nuestro boletín para recibir las últimas noticias en su bandeja de entrada.</p>
                            <form action="#" className="row form-dark" id="form_subscribe" method="post" name="form_subscribe">
                                <div className="col">
                                    <input className="form-control" id="txt_subscribe" name="txt_subscribe" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="enter your email" type="text" />
                                    <button type="submit" className="btn btn-main color-2" onClick={subscribeToNewsletter}>
                                        <i className="arrow_right bg-color-secondary"></i>
                                    </button>
                                    <div className="clearfix"></div>
                                </div>
                            </form>
                            <div className="spacer-10"></div>
                            <small>Su correo electrónico está seguro con nosotros. No hacemos spam.</small>
                        </div>
                    </div>
                </div>




            </div>

            <div className='row fixed-whatsapp-icon'>
                <div className="col-12 d-flex justify-content-end">
                    <a href="https://wa.me/524922190161/?text=Hola,%20me%20gustaría%20obtener%20más%20información%20sobre%20OpenCity.%20¿Podrías%20ayudarme?" target="_blank" rel="noopener noreferrer">
                        <img src="./img/acercade/logo-wasap.png" width="70" height="70" alt="WhatsApp" />
                    </a>
                </div>
            </div>



            <div className="subfooter">
                <div className="container">

                    <div className="row">
                        <div className="col-md-12">
                            <div className="de-flex">
                                <div className="de-flex-col">
                                    <span onClick={() => window.open("", "_self")}>
                                        <img alt="" className="f-logo d-1" src="./img//openCity/logo_back.png" />
                                        <img alt="" className="f-logo d-3" src="./img//openCity/logo_back.png" />
                                        <span className="copy">&copy; Copyright 2023 - Opencity by Smart Lines</span>
                                        <button
                                            className="btn btn-link"
                                            onClick={toggleModal}
                                            style={{
                                                backgroundColor: 'transparent',
                                                color: '#000',
                                                textDecoration: 'underline',
                                                padding: '10px 20px',
                                                fontSize: '1rem',
                                                border: 'none',
                                                cursor: 'pointer',
                                                outline: 'none'
                                            }}>
                                            Políticas de Privacidad
                                        </button>

                                    </span>
                                </div>
                                <div className="de-flex-col">
                                    <div className="social-icons">
                                        <a href="https://www.facebook.com/opencity.zac" target="_blank" rel="noopener noreferrer">
                                            <i className="fa fa-facebook fa-lg" style={{ color: '#1755C2' }}></i>
                                        </a>
                                        <a href="https://www.instagram.com/open_city1/" target="_blank" rel="noopener noreferrer">
                                            <i className="fa fa-instagram fa-lg" style={{ color: '#1755C2' }}></i>
                                        </a>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showModal && (
                <div className="modal show" style={{ display: 'block' }} onClick={toggleModal}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Políticas de Privacidad</h5>
                               
                            </div>
                            <div className="modal-body">
                                <h3>Políticas de Privacidad de OPEN CITY TECHNOLOGY S.A.P.I. de C.V.</h3>

                                <h4>Introducción</h4>
                                <p>
                                    En OPEN CITY TECHNOLOGY valoramos la privacidad y la protección de los datos personales de nuestros usuarios. Nuestras políticas explican cómo recopilamos, usamos y protegemos su información.
                                </p>

                                <h4>Recopilación de Datos</h4>
                                <p>
                                    Recopilamos datos a través de nuestras plataformas digitales para mejorar nuestros servicios. Esto incluye información proporcionada directamente por los usuarios y datos recogidos automáticamente.
                                </p>

                                <h4>Uso de la Información</h4>
                                <p>
                                    Utilizamos la información recopilada para proporcionar, mantener y mejorar nuestros servicios, así como para comunicaciones relacionadas con la empresa.
                                </p>

                                <h4>Derechos del Usuario</h4>
                                <p>
                                    Los usuarios tienen derecho a acceder, rectificar o eliminar sus datos personales, así como a limitar u oponerse a su tratamiento.
                                </p>

                                <h4>Seguridad de los Datos</h4>
                                <p>
                                    Tomamos medidas de seguridad para proteger los datos contra accesos no autorizados, alteraciones, divulgaciones o destrucciones.
                                </p>

                                <h4>Contacto</h4>
                                <p>
                                    Para más información o para ejercer sus derechos, contáctenos a través de [contacto@opencity.com.mx o 4922190161].
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </footer>
    );
};

export default Footer;