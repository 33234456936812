import React, { useState, useEffect } from 'react';

import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import ReactHtmlParser from 'react-html-parser';

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.sticky.white {
    background: #1755C2;
    border-bottom: solid 1px #1755C2;
  }
  header#myHeader.navbar .search #quick_search{
    color: #fff;
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader.navbar.white .btn, .navbar.white a, .navbar.sticky.white a{
    color: #fff;
  }
  header#myHeader .dropdown-toggle::after{
    color: rgba(255, 255, 255, .5);
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: block !important;
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: #1755C2;
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #fff;
    }
    .item-dropdown .dropdown a{
      color: #fff !important;
    }
  }
`;


export default function Adversiments() {

  const [additionalServices, setAdditionalServices] = useState([]);
  const pathBack = 'https://admin.opencity.com.mx';

  useEffect(() => {
    fetch(`${pathBack}/api/advertisements`)
      .then(response => response.json())
      .then(data => setAdditionalServices(data))
      .catch(error => console.error(error));
  }, []);

  return (
    <div>
      <GlobalStyles />
      <section className='jumbotron breadcumb no-bg' style={{ backgroundImage: `url(${'./img/background/subheader.jpg'})` }}>
        <div className='mainbreadcumb'>
          <div className='container'>
            <div className='row'>
              <div className="col-md-12 text-center">
                <h1>Promociones</h1>
                <p>Explora una variedad de promociones exclusivas diseñadas para convertir tu experiencia en un viaje memorable.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='container-fluid bg-gray' style={{ background: 'none' }}>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='text-center'>
                <h2>Promociones</h2>
                <div className="small-border"></div>
              </div>
            </div>
          </div>
          <div className='row'>

            {additionalServices.slice(0, 9).map(service => (
              <div className='col-lg-4 mt-4' key={service.id}>
                <div className='card'>
                  {service.image ? (
                    <img
                      src={`${pathBack}${service.image}`}
                      className='card-img-top media' // added 'media' class
                      alt={`Actividad ${service.id}`}
                    />
                  ) : null}

                  {service.video ? (
                    <video
                      width="100%"
                      height="200px" // you can set this to the height you want
                      controls
                      className='card-img-top media'>  // added 'media' class
                      <source
                        src={`${pathBack}${service.video}`}
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  ) : null}

                  <div className='card-body'>
                    <h5 className='card-title'>{service.title}</h5>
                    <span>{ReactHtmlParser(service.description)}</span>
                  </div>
                </div>
              </div>
            ))}



          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}