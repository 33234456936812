import Swal from 'sweetalert2';
const pathBack = 'https://admin.opencity.com.mx';


export const incrementRoomCount = (roomId, availableRooms, setAvailableRooms) => {
    const updatedRooms = availableRooms.map(room => {
        if (room.room.id === roomId && room.selectedCount < room.room.room_count) {
            return { ...room, selectedCount: room.selectedCount + 1 };
        }
        return room;
    });
    setAvailableRooms(updatedRooms);
};

export const decrementRoomCount = (roomId, availableRooms, setAvailableRooms) => {
    const updatedRooms = availableRooms.map(room => {
        if (room.room.id === roomId && room.selectedCount > 0) {
            return { ...room, selectedCount: room.selectedCount - 1 };
        }
        return room;
    });
    setAvailableRooms(updatedRooms);
};





// Incrementa el número de huéspedes
export const incrementGuestCount = (roomId, availableRooms, setAvailableRooms) => {
    const updatedRooms = availableRooms.map(room => {
        if (room.room.id === roomId && room.selectedGuestCount < room.room.capacity) {
            return { ...room, selectedGuestCount: room.selectedGuestCount + 1 };
        }
        return room;
    });
    setAvailableRooms(updatedRooms);
};



// components/bookEngine/utils.js

// Decrementa el número de huéspedes
export const decrementGuestCount = (roomId, availableRooms, setAvailableRooms) => {
    const updatedRooms = availableRooms.map(room => {
        if (room.room.id === roomId && room.selectedGuestCount > 0) {
            return { ...room, selectedGuestCount: room.selectedGuestCount - 1 };
        }
        return room;
    });
    setAvailableRooms(updatedRooms);
};


export const handleRoomCountChange = (roomId, count, availableRooms, setAvailableRooms, peopleCount) => {
    // Parsea la nueva cantidad a un entero
    const newCount = parseInt(count);

    // Actualiza la lista de habitaciones disponibles
    const updatedRooms = availableRooms.map(room => {
        if (room.room.id === roomId) {
            // Actualiza la cantidad seleccionada para la habitación específica
            return { ...room, selectedCount: newCount };
        }
        return room;
    });

    // Actualiza el estado con las habitaciones actualizadas
    setAvailableRooms(updatedRooms);

    // Llama a una función de validación si es necesario
    // (asegúrate de que la función validateSelection esté implementada correctamente)
    return validateSelection(updatedRooms, peopleCount);
}


const validateSelection = (availableRooms, peopleCount) => {
    let totalGuestCount = 0;
    for (let room of availableRooms) {
        if (room.selectedGuestCount > room.room.capacity) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `Has excedido la capacidad para la habitación ${room.room.name}`
            });
            return false;
        }

        if (room.selectedCount > room.room.room_count) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `Has excedido el número de habitaciones disponibles para ${room.room.name}`
            });
            return false;
        }

        totalGuestCount += room.selectedGuestCount || 0;
    }

    if (totalGuestCount > peopleCount) {
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Has excedido el número total de huéspedes'
        });
        return false;
    }

    return true;
}




export const fetchAvailableRooms = (accommodationId, startDate, endDate, setAvailableRooms, setShowRoomModal) => {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    return fetch(`${pathBack}/api/available-rooms`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': csrfToken  // Añade el token CSRF aquí
        },
        body: JSON.stringify({
            accommodationId: accommodationId,
            startDate: startDate,
            endDate: endDate
        })
    })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            setAvailableRooms(data);
            setShowRoomModal(true);
            return data;  // Esto es opcional, pero podría ser útil si quieres hacer algo con los datos después.
        })
        .catch(error => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `There was a problem with the fetch operation: ${error.message}`
            });
            throw error;
        });
};




export const handleChange = (e, setPeopleCount, checkDateAndPeopleSelectedFunc) => {
    const value = e.target.value;
    setPeopleCount(e.value);
    checkDateAndPeopleSelectedFunc(value);
    // Si el valor es un solo dígito numérico o está vacío, actualizamos el valor del input
    if (/^[0-9]$/.test(value) || value === "") {
        e.target.value = value;
    } else {
        e.target.value = e.target.value.slice(0, -1);
    }
};

export const checkDateAndPeopleSelected = (startDate, endDate, people, setIsDateAndPeopleSelected) => {
    if (startDate && endDate && people) {
        setIsDateAndPeopleSelected(true);
    } else {
        setIsDateAndPeopleSelected(false);
    }
};



export const calculateTotalCost = async (host, startDateTimeHost, endDateTimeHost,  numberOfPeople,setSummaryHost,) => {
    try {
        const response = await fetch(`${pathBack}/api/find-available-host`, { // Nota el uso de pathBack aquí
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                host,
                startDateTimeHost,
                endDateTimeHost, 
                numberOfPeople
            }),
        });

        if (response.ok) {
            const data = await response.json();

            console.log("respuesta",data)
            setSummaryHost(data)
        

            console.log("Data received:", data);
        } else {
            console.error(`Failed to send data: ${response.status} ${response.statusText}`);
        }
    } catch (error) {
        console.error(`Failed to send data: ${error}`);
    }
};
