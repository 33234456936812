import React from 'react';
import { Carousel } from 'react-bootstrap';

const AccommodationList = ({ accommodations, handleCheckAndShowModal, pathBack }) => {
    return (
        <div className="row">
            {accommodations.map(accommodation => (
                <div className="col-lg-6 col-12 mb-4" key={accommodation.id}>
                    <div className="nft__item m-0">
                        <div className="author_list_pp">
                            {/* Aquí puedes agregar contenido para la imagen del autor si es necesario */}
                        </div>
                        <Carousel interval={2000}>
                            {accommodation.images && accommodation.images.map((image, idx) => (
                                <Carousel.Item key={idx}>
                                    <img
                                        className="lazy nft__item_preview"
                                        src={`${pathBack}${image}`}
                                        alt={`Imagen ${idx + 1}`}
                                    />
                                </Carousel.Item>
                            ))}
                        </Carousel>
                        <div className="nft__item_info">
                            <span>
                                <h4>{accommodation.name}</h4>
                            </span>
                            <div className="nft__item_price">
                                <span>{accommodation.description}</span>
                            </div>
                            <div className="nft__item_price">
                                <span>{accommodation.address}</span>
                            </div>
                            <div className="nft__item_price">
                                <span>{accommodation.included_services}</span>
                            </div>
                            <div className="select-button">
                                <button className="btn btn-main btn-fullwidth color-2" onClick={() => handleCheckAndShowModal(accommodation.id)}>Ver habitaciones disponibles</button>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default AccommodationList;
