// useUrbanSpots.js
import { useState, useEffect } from 'react';

export const useUrbanSpots = (initialPath) => {
    const [urbanSpots, setUrbanSpots] = useState([]);
    const pathBack = initialPath;
    
    useEffect(() => {
        fetch(`${pathBack}/api/urban-spots`)
          .then(response => response.json())
          .then(data => {
            // Modificar los valores de imagen
            const modifiedUrbanSpots = data.map(urbanSpot => ({
              id: urbanSpot.id,
              name: urbanSpot.name,
              photo: `${pathBack}/urban_spots/${urbanSpot.photo}`,// Modifica la ruta de las imágenes según tus necesidades
              latitude: urbanSpot.latitude,
              longitude: urbanSpot.longitude
            }));
            setUrbanSpots(modifiedUrbanSpots);
          })
    
          .catch(error => console.error(error));
      }, []);

    return [urbanSpots, setUrbanSpots];
};