// CardComponent.jsx
import React from 'react';

const CardComponent = ({ urbanSpot, handleSelect, isSelected, setUrbanSpotId }) => {
  return (
    <div
      className={`card h-100 ${isSelected ? 'border border-3 border-primary' : 'border'}`}
      onClick={() => {
        handleSelect();
        setUrbanSpotId(urbanSpot.id);
      }}
    >
      <img src={urbanSpot.photo} alt={urbanSpot.name} className="card-img-top img-fluid" style={{ objectFit: 'cover', height: '200px' }} />
      <div className="card-body  text-white" >

        <h5 className="card-title">{urbanSpot.name}</h5>
      </div>
      <div className="card-footer">
        <a href={`https://www.google.com/maps/?q=${urbanSpot.latitude},${urbanSpot.longitude}`} className="btn btn-primary" target="_blank" rel="noopener noreferrer">Ver en Google Maps</a>
      </div>
    </div>
  );
};

export default CardComponent;
