import React from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import StarRating from './StarRating';
import Carousel from 'react-bootstrap/Carousel';

const pathBack = 'https://admin.opencity.com.mx';

const GuideList = ({ availableHosts, handleHostSelection, selectedHost }) => {
  return (
    <Row className='my-5'>
      {availableHosts.map((host) => (
        <Col xs={12} md={4} key={host.id} className="mb-4 mt-4">
          <Card className={`h-100 ${selectedHost && selectedHost.id === host.id ? 'highlighted' : ''}`}
           onClick={() => handleHostSelection(host)}>
            <Carousel interval={4000}>
              {host.photos && host.photos.map((photo, index) => (
                <Carousel.Item key={index}>
                  <img
                    className="d-block w-100"
                    src={`${pathBack}${photo}`}
                    alt={`Slide ${index + 1}`}
                    style={{ maxHeight: '300px' }} 
                  />
                </Carousel.Item>
              ))}
            </Carousel>
            <Card.Body>
              <Card.Title className="bg-light text-dark">{host.name}</Card.Title>
              <Card.Text>
                {host.tour_type && host.tour_type.length > 0 ? (
                  <div>
                    <p><strong>Tipo de Tour:</strong></p>
                    <ul>
                      {host.tour_type.map((tour, index) => (
                        <li key={index}>{tour}</li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <p>No hay información de Tipo de Tour disponible.</p>
                )}
                <p>{`Experiencia: ${host.experience}`}</p>
                <StarRating rating={host.rating} />
                <p>{`Precio actual: ${(host.current_price_for_dates.toFixed(2))} por hora`}</p>
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <button className="btn btn-primary mt-3" onClick={() => handleHostSelection(host)}>Seleccionar</button>
            </Card.Footer>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default GuideList;
