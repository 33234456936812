import React from 'react';

const DateAndPeopleSelection = ({ formattedDate, startDate,
  handleStartDateChange, endDate, handleEndDateChange,
  setPeopleCount, setIsDateAndPeopleSelected}) => {
    
  const handleChangeEvent = (event) => {
    const newPeopleCount = parseInt(event.target.value);
    setPeopleCount(newPeopleCount);
    setIsDateAndPeopleSelected(newPeopleCount > 0 &&startDate &&endDate); // Puedes ajustar la lógica según tus necesidades
  };
  
  return (
    <div>
      <h4>Selecciona un rango de fechas y te recomendaremos las mejores opciones disponibles</h4>
      <div className="row">
        <div className="col-md-4 col-sm-6 col-12 mb-2">
          <label htmlFor="startDate">Fecha de inicio:</label>
          <input
            type="date"
            className="form-control"
            id="startDate"
            min={formattedDate}
            value={startDate || ''}
            onChange={handleStartDateChange}
          />
        </div>
        <div className="col-md-4 col-sm-6 col-12 mb-2">
          <label htmlFor="endDate">Fecha de fin:</label>
          <input
            type="date"
            className="form-control"
            id="endDate"
            min={formattedDate}
            value={endDate || ''}
            onChange={handleEndDateChange}
          />
        </div>
        <div className="col-md-4 col-sm-12 col-12 mb-2">
          <label htmlFor="peopleCount">Número de personas:</label>
          <select 
            className="form-control"
            id="peopleCount"
            onChange={handleChangeEvent}
          >
            <option value="1">1 persona</option>
            <option value="2">2 personas</option>
            <option value="3">3 personas</option>
            <option value="4">4 personas</option>
            <option value="5">5 personas</option>
            <option value="6">6 personas</option>
            <option value="7">7 personas</option>
            <option value="8">8 personas</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default DateAndPeopleSelection;
