import React, { useState, useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';
import Clock from "../components/Clock";
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import Carousel from 'react-bootstrap/Carousel';
import styled from "styled-components";

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background:  #1755C2;
    border-bottom: solid 1px #dddddd;
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: #1755C2;
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #111;
    }
    .item-dropdown .dropdown a{
      color: #111 !important;
    }
  }
`;
const Modal = styled.div`
  display: ${props => props.open ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;
const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  max-width: 90%;  // Asegurarse de que el modal no sea demasiado ancho en pantallas pequeñas
  width: 800px; // Tamaño máximo del modal
  aspect-ratio: 16 / 9; // Mantener la relación de aspecto de un video de YouTube
  
  @media (max-width: 800px) {
    width: 100%;  // Hacer que el modal sea de ancho completo en pantallas pequeñas
  }
  
  iframe {
    width: 100%;
    height: 100%;
  }
`;



const Colection = function () {


  const [packagesBack, setPackagesBack] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const pathBack = 'https://admin.opencity.com.mx';

  useEffect(() => {
    fetch(`${pathBack}/api/packages`)
      .then(response => response.json())
      .then(data => setPackagesBack(data))
      .catch(error => console.error(error));
  }, []);



  const openModal = (host) => {
    setShowModal(true);
  }

  const closeModal = () => {
    setShowModal(false);
  }


  // Función para obtener el ID del video de YouTube de una URL
const getYoutubeVideoId = (url) => {


  // Primero verifica si es una URL de YouTube Shorts
  const shortsRegex = /\/shorts\/([^?&/#]*)/;
  const shortsMatch = shortsRegex.exec(url);

  if (shortsMatch && shortsMatch[1]) {
    return shortsMatch[1];
  }

  // Si no es un Short, entonces verifica si es una URL de video normal de YouTube
  const videoRegex = /[?&]v=([^&#]*)/;
  const videoMatch = videoRegex.exec(url);

  if (videoMatch && videoMatch[1]) {
    return videoMatch[1];
  }

  // Si no se encuentra ningún ID de video, retorna null
  return null;
};


  return (
    <div>
      <GlobalStyles />

      <section className='container'>
        {packagesBack.map((packageBack) => (
          <div className='row mt-md-5 pt-md-4' key={packageBack.id}>
            <div className="col-md-6 text-center">

              <Carousel>
                {packageBack.photos.map((photo, index) => (
                  <Carousel.Item key={index}>
                    <img
                      className="d-block w-100"
                      src={`${pathBack}${photo.path}`}
                      alt={`Slide ${index + 1}`}
                    />
                  </Carousel.Item>
                ))}
              </Carousel>

              {/* <Carousel.Item key={index}>
                  <img
                    className="d-block w-100"
                    src={`${pathBack}${photo}`}
                    alt={`Slide ${index + 1}`}
                  />
                </Carousel.Item> */}

              {/* <img src={`${pathBack}/packages/${packageBack.image}`} className="img-fluid img-rounded mb-sm-30" alt="" /> */}
            </div>
            <div className="col-md-6">
              <div className="item_info">
                <p>La promoción finaliza en:</p>
                <div className="de_countdown">
                  <Clock deadline={packageBack.end_date} />
                </div>
                <h2>{packageBack.title}</h2>
                <div>
                  <div className="item_info_counts">
                    <div className="item_info_previous_price">Precio anterior: ${packageBack.previous_price}</div>
                    <div className="item_info_offer_price">Precio de oferta: ${packageBack.offer_price}</div>
                  </div>
                </div>
                <h3>Servicios</h3>
                <div className="item_author">
                  <div className="author_list_info">
                    <span className="d-block">
                      {ReactHtmlParser(packageBack.description)}</span>
                  </div>
                </div>

                <button type="button" className="btn btn-primary" onClick={() => openModal(packageBack)}>
              Ver Video
            </button>

              </div>
            </div>
            <Modal open={showModal} onClick={closeModal}>
              <ModalContent onClick={(e) => e.stopPropagation()}>
                {packageBack && (
                  <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    {/* ... (tu código existente) */}
                    <iframe
                
                      src={`https://www.youtube.com/embed/${getYoutubeVideoId(packageBack.short_video)}`}
                      frameBorder="0"
                      allow="autoplay; encrypted-media"
                      allowFullScreen
                      title="Video del Paquete"
                      style={{ width: '100%', height: '100%' }}
                    />
                    <button className="btn-main lead m-auto mt-2" onClick={closeModal}>Cerrar</button>
                  </div>
                )}
              </ModalContent>
            </Modal>


          </div>

        ))}


      </section>

      <Footer />
    </div>
  );

}
export default Colection;