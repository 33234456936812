// SelectionCardSection.jsx
import React from 'react';
import SelectionCard from './SelectionCard';

const SelectionCardSection = ({ setSelectedOptionType, selectedOptionType }) => {
  return (
    <div className="container">
      <h4 className="text-center my-4">¿Estás buscando alojamiento, una experiencia turística, o ambas?</h4>
      <div className="row justify-content-center">
        <SelectionCard
          imageSrc="./img/iconos/1.png"
          title="Hospedaje"
          isSelected={selectedOptionType === 'hospedaje'}
          onSelect={() => setSelectedOptionType('hospedaje')}
        />
        <SelectionCard
          imageSrc="./img/iconos/2.png"
          title="Experiencia turística"
          isSelected={selectedOptionType === 'anfitrion'}
          onSelect={() => setSelectedOptionType('anfitrion')}
        />
        <SelectionCard
          imageSrc="./img/iconos/3.png"
          title="Ambos"
          isSelected={selectedOptionType === 'ambos'}
          onSelect={() => setSelectedOptionType('ambos')}
        />
      </div>
    </div>
  );
};

export default SelectionCardSection;

