import { tr } from 'date-fns/locale';
import React, { useState } from 'react';
import Swal from 'sweetalert2';

// Correcto
const PaymentForm = ({ selectedOptionType, summary, startDate, endDate, summaryHost,
  startDateTimeHost, endDateTimeHost
}) => {
  const { summary: summaryDetails = [], totalPrice = 0 } = summary || {};

  const [paymentMethod, setPaymentMethod] = useState('card');
  const [cardNumber, setCardNumber] = useState('');
  const [expirationDate, setExpirationDate] = useState('');
  const [cvc, setCvc] = useState('');

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);


  const [buyerName, setBuyerName] = useState('');
  const [buyerEmail, setBuyerEmail] = useState('');
  const [buyerPhoneNumber, setBuyerPhoneNumber] = useState('');

  const [cardNumberError, setCardNumberError] = useState('');
  const [expirationDateError, setExpirationDateError] = useState('');
  const [cvcError, setCvcError] = useState('');

  // const validateCreditCardForm = ({
  // }) => {
  //   setCardNumberError('');
  //   setExpirationDateError('');
  //   setCvcError('');

  //   if (paymentMethod === 'card') {
  //     if (!cardNumber.match(/^\d{1,16}$/)) {
  //       setCardNumberError('Por favor, ingresa un número de tarjeta válido de hasta 16 dígitos.');
  //     }
  //     if (!expirationDate.match(/^\d{0,2}\/\d{0,2}$/)) {
  //       setExpirationDateError('Por favor, ingresa una fecha de expiración válida (MM/AA).');
  //     }
  //     if (!cvc.match(/^\d{0,3}$/)) {
  //       setCvcError('Por favor, ingresa un CVC válido de hasta 3 dígitos.');
  //     }
  //   }
  // };

  const processPayment = () => {

    setIsButtonDisabled(true);
    // Realiza la lógica de procesamiento de pago aquí
    // Esto podría incluir la llamada a una API de pago, enviar datos al servidor, etc.

    // Construye un objeto con los datos de pago
    let paymentData = {
      paymentMethod,
      cardNumber,
      expirationDate,
      cvc,
      buyerName,
      buyerEmail,
      buyerPhoneNumber,
      selectedOptionType
    };

    if (summary && Object.keys(summary).length) {
      paymentData = {
        ...paymentData,
        summaryDetails,
        totalPrice,
        startDate,
        endDate
      };
    }

    if (summaryHost && Object.keys(summaryHost).length) {
      paymentData = {
        ...paymentData,
        summaryHost,
        startDateTimeHost,
        endDateTimeHost,
        totalPrice: summaryHost.totalCost
      };
    }


    // Realiza una solicitud POST al servidor con los datos de pago
    fetch('https://admin.opencity.com.mx/api/reservar', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(paymentData),
    })
      .then((response) => response.json())
     
      .then((data) => {

        console.log(data)
        if (data.success) {  // Verifica si la reserva fue exitosa
          Swal.fire(  // Muestra una alerta
            '¡Reservación Exitosa!',
            'Tu reservación ha sido confirmada.',
            'success'
          ).then(() => {
            // Limpia los datos del formulario (ajusta esto según tus necesidades)
            setBuyerName('');
            setBuyerEmail('');
            setBuyerPhoneNumber('');
            // ... (otros campos según sea necesario)

            // Redirige a la pantalla 1 (ajusta esto según tus necesidades)
            window.location.href = '/';  // O utiliza tu manejador de rutas preferido
          });
        } else {
          setIsButtonDisabled(false);
          // Maneja el error como prefieras
          console.error('Error al procesar la reservación');
        }
      })
      .catch((error) => {

        setIsButtonDisabled(false);
        console.error('Error en la solicitud al servidor', error);
      });
  };
  // const handleCardNumberChange = (e) => {
  //   if (e.target.value.length <= 16) {
  //     setCardNumber(e.target.value);
  //   }
  //   validateCreditCardForm();
  // };

  // const handleExpirationDateChange = (e) => {
  //   if (e.target.value.length <= 5) {
  //     setExpirationDate(e.target.value);
  //   }
  //   validateCreditCardForm();
  // };

  // const handleCvcChange = (e) => {
  //   if (e.target.value.length <= 3) {
  //     setCvc(e.target.value);
  //   }
  //   validateCreditCardForm();
  // };


  console.log(startDateTimeHost)
  console.log(endDateTimeHost)
  return (
    <>
      <div className='row'>
        <h2 className="mb-3">Detalles de la Compra</h2>
      </div>

      <div className="row">
        <div className='col-md-5'>
          <h3 className="mb-3 text-center">Resumen de la Compra</h3>
          <table className="table table-hover">
            <tbody>
              <tr className="bg-light">
                <td><strong>Tipo de Servicio:</strong></td>
                <td className="text-end">
                  {selectedOptionType === 'hospedaje' && 'Hospedaje'}
                  {selectedOptionType === 'anfitrion' && 'Experiencia turística'}
                  {selectedOptionType === 'ambos' && 'Host/Hospedaje'}

                </td>
              </tr>
              {
                startDate && (selectedOptionType === 'hospedaje' || selectedOptionType === 'ambos') &&
                <tr className="bg-light">
                  <td><strong>Fecha de Inicio de Reservación:</strong></td>
                  <td className="text-end">{startDate}</td>
                </tr>
              }
              {
                endDate && (selectedOptionType === 'hospedaje' || selectedOptionType === 'ambos') &&
                <tr className="bg-light">
                  <td><strong>Fecha Fin de Reservación:</strong></td>
                  <td className="text-end">{endDate}</td>
                </tr>
              }

              {
                startDateTimeHost && (selectedOptionType === 'anfitrion' || selectedOptionType === 'ambos') &&
                <tr className="bg-light">
                  <td><strong>Fecha y Hora de Inicio de Guía:</strong></td>
                  <td className="text-end">
                    {new Date(startDateTimeHost).toLocaleString('es-ES', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: true
                    })}
                  </td>
                </tr>
              }
              {
                endDateTimeHost && (selectedOptionType === 'anfitrion' || selectedOptionType === 'ambos') &&
                <tr className="bg-light">
                  <td><strong>Fecha y Hora de Fin de Guía:</strong></td>
                  <td className="text-end">
                    {new Date(endDateTimeHost).toLocaleString('es-ES', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: true
                    })}
                  </td>
                </tr>
              }


              {
                summaryDetails.length > 0 && (selectedOptionType === 'hospedaje' || selectedOptionType === 'ambos') &&
                <tr className="bg-light">
                  <td colSpan={2}>
                    <div className="room-summary">
                      {summaryDetails.map((item, index) => (
                        <div className="room-details" key={index}>
                          <div className="room-name">
                            <strong>{item.roomName}</strong>
                          </div>
                          <div className="guest-info">
                            <strong>Huéspedes: </strong>{item.count}
                          </div>
                          <div className="room-info">
                            <span>Habitaciones: {item.requiredRooms}</span>
                            <span>P. Noche: ${item.totalPriceForRoomType / item.requiredRooms}</span>
                            <span>Subt.: ${item.totalPriceForRoomType}</span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </td>
                </tr>
              }

              {
                summaryHost && (selectedOptionType === 'anfitrion' || selectedOptionType === 'ambos') &&
                <tr className="bg-light">
                  <td colSpan={2}>
                    <div className="host-summary">
                      <div className="host-details">
                        <div className="host-name">
                          <strong>Experiencia turística: </strong>{summaryHost.host || 'N/A'}
                        </div>
                        <div className="host-name">
                          <strong>Número de personas: </strong>{summaryHost.numberOfPeople || 'N/A'}
                        </div>
                        <div className="host-info">
                          <span>Tarifa del Servicio: ${summaryHost.hourlyPrice || 0}</span>

                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              }


              {
                totalPrice > 0 && selectedOptionType === 'hospedaje' &&
                <tr className="table-success">
                  <td><strong>Total a Pagar:</strong></td>
                  <td className="text-end">${totalPrice}</td>
                </tr>
              }


              {
                summaryHost && (selectedOptionType === 'anfitrion') &&
                <tr className="table-success">
                  <td><strong>Total a Pagar:</strong></td>
                  <td className="text-end">${summaryHost.totalCost || 0}</td>
                </tr>
              }

              {
                selectedOptionType === 'ambos' &&
                <tr >
                  <td><strong>Total a Pagar Anfitrión:</strong></td>
                  <td className="text-end">${summaryHost.totalCost || 0}</td>
                </tr>

              }
              {
                selectedOptionType === 'ambos' &&
                <tr >
                  <td><strong>Total a pagar Hospedaje:</strong></td>
                  <td className="text-end">${totalPrice}</td>
                </tr>

              }

              {
                selectedOptionType === 'ambos' &&
                <tr className="table-success">
                  <td><strong>Total a pagar:</strong></td>
                  <td className="text-end">${(summaryHost.totalCost || 0) + totalPrice}</td>

                </tr>

              }

            </tbody>
          </table>
        </div>


        <div className='col-md-7'>
          <div className="mt-4">
            <div className="mb-3 col-md-4">
              <label className="form-label">Método de Pago:</label>
              <select
                className="form-select"
                onChange={(e) => setPaymentMethod(e.target.value)}
                value={paymentMethod}
              >
                <option value={""}>Selecciona un tipo de pago</option>
                <option value="transferencia">Transferencia</option>
                {/* <option value="card">Tarjeta de Crédito</option>
                <option value="oxxo">Pago en Efectivo (OXXO)</option> */}
              </select>
            </div>

            {/* {paymentMethod === 'card' && (
              <div className="row">
                <div className="mb-3 col-md-4">
                  <label className="form-label">Número de Tarjeta:</label>
                  <input
                    type="text"
                    className={`form-control ${cardNumberError && 'is-invalid'}`}
                    value={cardNumber}
                    onChange={handleCardNumberChange}
                  />
                  {cardNumberError && (
                    <div className="invalid-feedback">{cardNumberError}</div>
                  )}
                </div>
                <div className="mb-3 col-md-4">
                  <label className="form-label">Fecha de Expiración:</label>
                  <input
                    type="text"
                    className={`form-control ${expirationDateError && 'is-invalid'}`}
                    value={expirationDate}
                    onChange={handleExpirationDateChange}
                  />
                  {expirationDateError && (
                    <div className="invalid-feedback">{expirationDateError}</div>
                  )}
                </div>
                <div className="mb-3 col-md-4">
                  <label className="form-label">CVC:</label>
                  <input
                    type="text"
                    className={`form-control ${cvcError && 'is-invalid'}`}
                    value={cvc}
                    onChange={handleCvcChange}
                  />
                  {cvcError && (
                    <div className="invalid-feedback">{cvcError}</div>
                  )}
                </div>
              </div>
            )} */}



            <div className="mb- row col-md-12">
              <div className="mb-3 col-md-4">
                <label className="form-label">Nombre Completo:</label>
                <input
                  type="text"
                  className="form-control"
                  value={buyerName}
                  onChange={(e) => setBuyerName(e.target.value)}
                />
              </div>

              <div className="mb-3 col-md-4">
                <label className="form-label">Correo Electrónico:</label>
                <input
                  type="email"
                  className="form-control"
                  value={buyerEmail}
                  onChange={(e) => setBuyerEmail(e.target.value)}
                />
              </div>
              <div className="mb-3 col-md-4">
                <label className="form-label">Número de Teléfono:</label>
                <input
                  type="tel"
                  className="form-control"
                  value={buyerPhoneNumber}
                  onChange={(e) => setBuyerPhoneNumber(e.target.value)}
                />
              </div>

              <button className="btn btn-primary mt-3" onClick={() => {
                processPayment(); // Llama a la función de procesamiento de pago
              }}>Confirmar reservación</button>
            </div>

          </div>

        </div>

      </div>

    </>

  );
};

export default PaymentForm;
