import React, { useState, useEffect } from 'react';
import Reveal from 'react-awesome-reveal';
import { keyframes } from "@emotion/react";
import "react-datepicker/dist/react-datepicker.css";
import Swal from 'sweetalert2';
import ThirdScreen from './bookEngine/ThirdScreen';
import {
  checkDateAndPeopleSelected,
  fetchAvailableRooms, calculateTotalCost
} from './bookEngine/utils'

import SelectionCardSection from './bookEngine/SelectionCardSection';

import RoomModal from './bookEngine/RoomModal';
import Destinations from './bookEngine/Destinations';

import DateAndPeopleSelection from './bookEngine/DateAndPeopleSelection';

import AccommodationList from './bookEngine/AccommodationList'
import { useUrbanSpots } from './bookEngine/hooks/useUrbanSpots';
import PaymentForm from './bookEngine/PaymentForm';
import HostsAndDateTimeSection from './bookEngine/HostsAndDateTimeSection'; // Importar el componente

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;

const SliderMainParticle = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [urbanSpots, setUrbanSpots] = useUrbanSpots('https://admin.opencity.com.mx');
  const [accommodations, setAccomodations] = useState([]);
  const [selectedOptionType, setSelectedOptionType] = useState(null);
  const [urbanSpotId, setUrbanSpotId] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isDateAndPeopleSelected, setIsDateAndPeopleSelected] = useState(false);
  const [selectedGuestCounts, setSelectedGuestCounts] = useState({});
  const [peopleCount, setPeopleCount] = useState(null);

  const [showRoomModal, setShowRoomModal] = useState(false);
  const [availableRooms, setAvailableRooms] = useState([]);
  const today = new Date();
  const formattedDate = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;

  const [availableHosts, setAvailableHosts] = useState([]);
  const [isRoomSelected, setIsRoomSelected] = useState(false);

  const [startDateTimeHost, setStartDateTimeHost] = useState(new Date());
  const [endDateTimeHost, setEndDateTimeHost] = useState(new Date());

  const [numberOfPeople, setNumberOfPeople] = useState(1); // Estado para número de personas

  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTime, setSelectedTime] = useState('');

  const [selectedHost, setSelectedHost] = useState(null);

  const [showPaymentStep, setShowPaymentStep] = useState(false);

  const [hasVisitedScreen, setHasVisitedScreen] = useState(false);

  const [currentStep, setCurrentStep] = useState('destinationAndTypeSelection');

  //Sumary
  const [summary, setSummary] = useState([]);
  const [summaryHost, setSummaryHost] = useState([]);

  const [loading, setLoading] = useState(true);

  const handleStartDateChange = (event) => {
    const newStartDate = event.target.value;
    setStartDate(event.target.value);
    checkDateAndPeopleSelected(event.target.value, endDate, peopleCount, setIsDateAndPeopleSelected);

    if (endDate && new Date(newStartDate) >= new Date(endDate)) {
      Swal.fire({
        icon: 'error',
        title: 'Error en la fecha',
        text: 'La fecha de inicio no puede ser mayor o igual a la fecha de fin'
      });
      return;
    }

    setStartDate(newStartDate);
  };

  
  const clearState = () => {
    setStartDateTimeHost(new Date());
    setEndDateTimeHost(new Date());
    setStartDate(null);
    setEndDate(null);
    setSummary([]);
    setSummaryHost([]);
    setPeopleCount(null)
    setSelectedHost(null)
    setSelectedGuestCounts({})
    setIsRoomSelected(false)
    setNumberOfPeople(0)
  };

  const handleBackButtonClick = () => {
    switch (currentStep) {
      case 'selectedOptionType':
        setSelectedOptionType(null); // Limpiar el tipo de opción seleccionado
        setCurrentStep('selectedOption'); // Retroceder al paso de selección de opción
        break;

      case 'selectedOption':
        clearState();
        setSelectedOption(null); // Limpiar la opción seleccionada
        setCurrentStep('destinationAndTypeSelection'); // Retroceder al paso de selección de destino y tipo
        break;

      case 'dateAndPeopleSelection':
        clearState();
        setIsDateAndPeopleSelected(false); // Revertir la selección de fecha y personas
        setCurrentStep('selectedOptionType'); // Retroceder al paso de selección de tipo de opción
        break;

      case 'accommodationSelection':
        clearState();
        setIsRoomSelected(false); // Revertir la selección de alojamiento
        setCurrentStep('dateAndPeopleSelection'); // Retroceder al paso de selección de fecha y personas
        break;

      case 'hostSelection':
        clearState();
        setSelectedHost(null); // Limpiar la selección de anfitrión
        setCurrentStep('destinationAndTypeSelection');  // Retroceder al paso de selección de alojamiento
        break;

      case 'paymentInformation':
        clearState();
        setCurrentStep('hostSelection'); // Retroceder al paso de selección de anfitrión
        break;

      default:
        console.log('Esta es la primera pantalla');
        break;
    }
  };

  useEffect(() => {
    console.log('currentStep cambiado: ', currentStep); // Añadido para depuración
  }, [currentStep]);

  const handleEndDateChange = (event) => {
    const newEndDate = event.target.value;
    setEndDate(event.target.value);
    checkDateAndPeopleSelected(startDate, event.target.value, peopleCount, setIsDateAndPeopleSelected);

    if (startDate && new Date(startDate) >= new Date(newEndDate)) {
      Swal.fire({
        icon: 'error',
        title: 'Error en la fecha',
        text: 'La fecha de fin no puede ser menor o igual a la fecha de inicio'
      });
      return;
    }

    setEndDate(newEndDate);
  };

  const pathBack = 'https://admin.opencity.com.mx';

  useEffect(() => {
    if (urbanSpotId && startDate && endDate) {
      //https://admin.opencity.com.mx/api/accommodations/6/acommodations
      fetch(`${pathBack}/api/accommodations/${urbanSpotId}/${startDate}/${endDate}/acommodations`)
        .then(response => response.json())
        .then(data => {
          // Modificar los valores de imagen
          const accommodations = data.map(accomodation => ({
            id: accomodation.id,
            name: accomodation.name,
            address: accomodation.address,
            description: accomodation.description,
            included_services: accomodation.included_services,
            latitude: accomodation.description,
            longitude: accomodation.longitude,
            images: accomodation.photos.map(photo => photo.path) // Mapeamos solo los paths de las fotos
          }));
          setAccomodations(accommodations);
        })
        .catch(error => console.error(error));
    }
  }, [urbanSpotId, startDate, endDate]);

  const handleCheckAndShowModal = (accommodationId) => {
    fetchAvailableRooms(accommodationId, startDate, endDate, setAvailableRooms, setShowRoomModal);
  }

  const fetchAvailableHosts = async (startDateTimeHost, endDateTimeHost, urbanSpotId) => {
    try {
      const response = await fetch(`https://admin.opencity.com.mx/api/available-hosts?startTime=${startDateTimeHost}&endTime=${endDateTimeHost}&urbanSpotId=${urbanSpotId}`);

      if (response.ok) {
        const data = await response.json();
        console.log(data.available_hosts)
        return data.available_hosts;
      } else {
        console.error(`Failed to fetch available hosts: ${response.status} ${response.statusText}`);
      }
    } catch (error) {
      console.error(`Failed to fetch available hosts: ${error}`);
    }
  };

  useEffect(() => {
    if (startDateTimeHost && endDateTimeHost && urbanSpotId) {
      const startDate = new Date(startDateTimeHost);
      const endDate = new Date(endDateTimeHost);
      const timeDifferenceInHours = (endDate - startDate) / 1000 / 60 / 60;

      if (!selectedHost) {
        fetchAvailableHosts(startDateTimeHost, endDateTimeHost, urbanSpotId)
          .then(data => {
            setAvailableHosts(data);
          });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'La duración debe ser de al menos 1 hora y no más de 5 horas.',
        });
      }
    }
  }, [startDateTimeHost, endDateTimeHost]);

  useEffect(() => {
    if (selectedHost) {
      calculateTotalCost(selectedHost, startDateTimeHost, endDateTimeHost, numberOfPeople, setSummaryHost)
    }
  }, [selectedHost]);

  const handleRoomSelection = () => {
    setIsRoomSelected(true);
    setShowPaymentStep(true);
  };

  const renderDestinationAndTypeSelection = () => {
    if (!selectedOption) {
      return (
        <>
          {/* Aquí no necesitamos un botón "Atrás" porque este es el primer paso */}
          <Destinations
            urbanSpots={urbanSpots}
            selectedOption={selectedOption}
            setSelectedOption={(option) => {
              setSelectedOption(option);
              setCurrentStep('selectedOption');  // Cambiamos el paso actual aquí
            }}
            setUrbanSpotId={setUrbanSpotId}
          />
        </>
      );
    } else if (selectedOption && !selectedOptionType) {
      return (
        <>
          {selectedOption && (
            <button
              onClick={() => {
                setSelectedOption(null); // Limpia la selección de opción
                setCurrentStep('destinationAndTypeSelection');  // Cambiamos el paso actual aquí
              }}
              className="btn btn-secondary mb-3 mt-5"
            >
              Atrás
            </button>
          )}
          <SelectionCardSection
            setSelectedOptionType={(type) => {
              setSelectedOptionType(type);
              setCurrentStep('selectedOptionType');  // Cambiamos el paso actual aquí
            }}
            selectedOptionType={selectedOptionType}
          />
        </>
      );
    }
    return null;
  };

  const renderDateAndPeopleSelection = () => {
    if ((selectedOptionType === 'hospedaje' || selectedOptionType === 'ambos') && !isDateAndPeopleSelected) {
      return (
        <>
          <button
            onClick={() => {
              handleBackButtonClick();
              setCurrentStep('selectedOptionType');  // Cambiamos el paso actual aquí
              // Puedes también resetear otros estados aquí si es necesario
            }}
            className="btn btn-secondary mb-3 mt-5"
          >
            Atrás
          </button>

          <DateAndPeopleSelection
            formattedDate={formattedDate}
            startDate={startDate}
            handleStartDateChange={handleStartDateChange}
            endDate={endDate}
            handleEndDateChange={handleEndDateChange}
            setPeopleCount={setPeopleCount}
            setIsDateAndPeopleSelected={(isSelected) => {
              setIsDateAndPeopleSelected(isSelected);
              if (isSelected) {
                setCurrentStep('dateAndPeopleSelected');  // Cambiamos el paso actual aquí si las fechas y las personas están seleccionadas
              }
            }}
          />
        </>
      );
    }
    return null;
  };

  const renderHostSelection = () => {
    if ((selectedOptionType === 'ambos') && isDateAndPeopleSelected && !selectedHost && isRoomSelected) {
      return (
        <>
          <button
            onClick={() => {
              handleBackButtonClick();
              setCurrentStep('accommodationSelection'); // Cambiamos el paso actual aquí
            }}
            className="btn btn-secondary mb-3"
          >
            Atrás
          </button>

          <ThirdScreen
            availableHosts={availableHosts}
            startDateTimeHost={startDateTimeHost}
            endDateTimeHost={endDateTimeHost}
            urbanSpotId={urbanSpotId}
            setStartDateTimeHost={setStartDateTimeHost}
            setEndDateTimeHost={setEndDateTimeHost}
            selectedDate={selectedDate}
            selectedTime={selectedTime}
            selectedHost={selectedHost}
            setSelectedHost={setSelectedHost}
            setHasVisitedScreen={setHasVisitedScreen}
            setNumberOfPeople={setNumberOfPeople}
            numberOfPeople={numberOfPeople} // Pasar el estado como prop
          />
        </>
      );
    }
    return null;
  };

  const renderAccommodationSelection = () => {
    if ((selectedOptionType === 'hospedaje' || selectedOptionType === 'ambos') && isDateAndPeopleSelected && !isRoomSelected) {
      return (
        <>
          <button
            onClick={() => {
              handleBackButtonClick();
              setCurrentStep('dateAndPeopleSelection'); // Cambiamos el paso actual aquí
            }}
            className="btn btn-secondary mb-3 mt-5"
          >
            Atrás
          </button>
          <AccommodationList
            accommodations={accommodations}
            handleCheckAndShowModal={handleCheckAndShowModal}
            pathBack={pathBack}
          />
          <RoomModal
            showRoomModal={showRoomModal}
            setShowRoomModal={setShowRoomModal}
            availableRooms={availableRooms}
            setAvailableRooms={setAvailableRooms}
            peopleCount={peopleCount}
            handleRoomSelection={handleRoomSelection}
            selectedGuestCounts={selectedGuestCounts}
            setSelectedGuestCounts={setSelectedGuestCounts}
            startDate={startDate}
            endDate={endDate}
            setSummary={setSummary}
            setIsRoomSelected={setIsRoomSelected}
          />
        </>
      );
    }
    return null;
  };

  const renderSecondHostSelection = () => {
    if (selectedOptionType === 'anfitrion' && !selectedHost) {
      return (
        <div>
          <button
            onClick={() => {
              handleBackButtonClick();
              setCurrentStep('hostSelection'); // Cambia el paso actual aquí al paso anterior, que es 'hostSelection'
            }}
            className="btn btn-secondary mb-3 mt-5"
          >
            Atrás
          </button>

          <ThirdScreen
            availableHosts={availableHosts}
            startDateTimeHost={startDateTimeHost}
            endDateTimeHost={endDateTimeHost}
            urbanSpotId={urbanSpotId}
            setStartDateTimeHost={setStartDateTimeHost}
            setEndDateTimeHost={setEndDateTimeHost}
            selectedDate={selectedDate}
            selectedTime={selectedTime}
            selectedHost={selectedHost}
            setSelectedHost={setSelectedHost}
            setNumberOfPeople={setNumberOfPeople}
            numberOfPeople={numberOfPeople} // Pasar el estado como prop
       
       />
        </div>
      );
    }
    return null;
  };

  const renderPaymentInformation = () => {
    if (
      (selectedOptionType === 'hospedaje' && isRoomSelected) ||
      (selectedOptionType === 'anfitrion' && selectedHost) ||
      (selectedOptionType === 'ambos' && (selectedHost))
    ) {
      return (
        <div className="mt-4">
          <button
            onClick={() => {
              handleBackButtonClick();
              if (selectedOptionType === 'hospedaje' || selectedOptionType === 'ambos') {
                setCurrentStep('paymentInformation');
              } else if (selectedOptionType === 'anfitrion') {
                setCurrentStep('paymentInformation');
              }
            }}
            className="btn btn-secondary mb-3 mt-5"
          >
            Atrás
          </button>

          <PaymentForm
            selectedOptionType={selectedOptionType}
            summary={summary}
            startDate={startDate}
            endDate={endDate}
            summaryHost={summaryHost}
            startDateTimeHost={startDateTimeHost}
            endDateTimeHost={endDateTimeHost}
            numberOfPeople={numberOfPeople} // Pasar el estado como prop
          />
        </div>
      );
    }
    return null;
  };

  const renderFinalPaymentScreen = () => {
    if (isRoomSelected && selectedOptionType && showPaymentStep) {
      return (
        <div className="mt-4">
          <h2>¡Gracias por tu reserva!</h2>
          {/* Render the final payment details and confirmation */}
        </div>
      );
    }
    return null;
  };

  return (
    <div className="container">
      {renderDestinationAndTypeSelection()}
      {renderDateAndPeopleSelection()}
      {renderHostSelection()}
      {renderAccommodationSelection()}
      {renderSecondHostSelection()}
      {renderPaymentInformation()}
      <div className="mb-sm-30 my-3"></div>
    </div>
  );
};

export default SliderMainParticle;
